import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';

import '../utilities/i18n';

import { isLoggedIn, redirectToWISEflow } from '../api/login';

import Header from '../components/Header';
import Footer from '../components/Footer';

import WiseflowLoginView from '../views/WiseflowLogin';
import SelectLoginView from '../views/SelectLogin';
import SearchLoginView from '../views/SearchLogin';
import LicenseLoginView from '../views/LicenseLogin';
import FrontpageView from '../views/Frontpage';
import EnvironmentModal from '../components/EnvironmentModal';

// Lazy loaded views
const Error404View = React.lazy(() => import('../views/Error/Err404'));
const ErrorLicenseView = React.lazy(() => import('../views/Error/ErrLicense'));
const RecoverLoginView = React.lazy(() => import('../views/RecoverLogin'));
const AliasRedirectView = React.lazy(() => import('../views/AliasRedirect'));
const ChangelogView = React.lazy(() => import('../views/Changelog'));
const ChangelogEntry = React.lazy(
  () => import('../views/Changelog/Entry/Entry')
);

export interface HeaderFooterRouteProps extends RouteProps {
  withLoginButton?: boolean;
}

export const HeaderFooterRoute = (
  props: HeaderFooterRouteProps
): JSX.Element => {
  return (
    <>
      <Header login={props.withLoginButton} />
      <main>
        <Route {...props} />
      </main>
      <Footer />
    </>
  );
};

export const RedirectIfLoggedInRoute = (props: RouteProps): JSX.Element => {
  if (isLoggedIn()) {
    return (
      <Route
        component={() => {
          redirectToWISEflow();
          return null;
        }}
      />
    );
  } else {
    return <HeaderFooterRoute {...props} />;
  }
};

export const LegacyLoginRedirect = (props: RouteProps): JSX.Element => {
  const params = new URLSearchParams(props.location?.search);
  const licenseId = params.get('subdomain');

  if (licenseId) {
    return <Redirect to={`/login/license/${licenseId}`} />;
  } else {
    return <Redirect to="/login" />;
  }
};

function Routes(): JSX.Element {
  return (
    <Router>
      <Suspense fallback="">
        <EnvironmentModal />

        <Switch>
          <HeaderFooterRoute
            path="/"
            component={FrontpageView}
            exact
            withLoginButton
          />

          <RedirectIfLoggedInRoute
            path="/login"
            component={SelectLoginView}
            exact
          />

          <RedirectIfLoggedInRoute
            path="/login/search"
            component={SearchLoginView}
            exact
          />

          <RedirectIfLoggedInRoute
            path="/login/wiseflow"
            component={WiseflowLoginView}
            exact
          />
          <RedirectIfLoggedInRoute
            path="/login/wiseflow/recover"
            component={RecoverLoginView}
            exact
          />

          <RedirectIfLoggedInRoute
            path="/login/license/:id"
            component={LicenseLoginView}
            exact
          />
          <RedirectIfLoggedInRoute
            path="/login/license/:id/wiseflow"
            component={WiseflowLoginView}
            exact
          />

          <RedirectIfLoggedInRoute
            path="/login/error/license"
            component={ErrorLicenseView}
            exact
          />
          <RedirectIfLoggedInRoute
            path="/login/error/404"
            component={Error404View}
            exact
          />

          <RedirectIfLoggedInRoute
            path="/login/:country/:alias"
            component={AliasRedirectView}
            exact
          />

          <HeaderFooterRoute
            path="/changelog"
            component={ChangelogView}
            exact
            withLoginButton
          />
          <HeaderFooterRoute
            path="/changelog/tags/:tags?"
            component={ChangelogView}
            exact
            withLoginButton
          />
          <HeaderFooterRoute
            path="/changelog/:id"
            component={ChangelogEntry}
            exact
            withLoginButton
          />

          <LegacyLoginRedirect path="/login/index.php" />

          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default Routes;
