import React from 'react';
import './ListItem.scss';
import { Image, WfMenu, WfButton } from '@uniwise/flow-ui-react';
import { Link } from 'react-router-dom';
import { Institution } from '../../../interfaces/Institution';

function ListItem(institution: Institution): JSX.Element {
  return (
    <WfMenu.Item
      className="ListItem"
      as={'div'}
      tabIndex={-1}
      key={institution.id}
    >
      <WfButton
        basic
        size="large"
        as={Link}
        to={`/login/license/${institution.id}`}
        content={
          <>
            <div>{institution.name}</div>
            <Image
              src={
                'https://europe.wiseflow.net/pics/logos/dark/' +
                institution.id +
                '.png'
              }
              size="mini"
            />
          </>
        }
      />
    </WfMenu.Item>
  );
}

export default ListItem;
