import React from 'react';
import { Link } from 'react-router-dom';
import { Responsive, Image, WfMenu, WfButton } from '@uniwise/flow-ui-react';
import './Header.scss';

import logo from './logo.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';
import { isLoggedIn, redirectToWISEflow } from '../../api/login';

export interface HeaderProps {
  login?: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const loggedIn = isLoggedIn();

  return (
    <header>
      {/* Big device */}
      <Responsive minWidth={760}>
        <WfMenu secondary>
          <WfMenu.Item
            as={Link}
            to="/"
            id="wiseflow-logo"
            role="link"
            aria-label="WISEflow"
            content={<Image src={logo} alt="WISEflow" />}
          />

          <WfMenu.Item
            as="a"
            href="https://www.uniwise.co.uk/blog-landing"
            id="blog-link"
            role="link"
            content={t('landing.common.button.blog')}
          />

          <WfMenu.Item
            as={Link}
            to="/changelog"
            id="changelog-link"
            role="link"
            content={t('landing.common.button.changelog')}
          />

          <WfMenu.Item
            fitted
            position="right"
            as="div"
            disabled
            style={{ opacity: 1 }}
            content={<LanguageSelector />}
          />

          {props.login && (
            <WfMenu.Item fitted as="div" disabled>
              {!loggedIn && (
                <WfButton
                  as={Link}
                  to="/login"
                  color="green"
                  icon={{
                    icon: 'unlock',
                    label: t('landing.common.button.logIn'),
                  }}
                  labelPosition="right"
                  content={t('landing.common.button.logIn')}
                  id="login-link"
                  role="link"
                />
              )}
              {loggedIn && (
                <WfButton
                  onClick={redirectToWISEflow}
                  color="green"
                  icon={{
                    icon: 'arrow right',
                    label: t('landing.common.button.goToWiseflow'),
                  }}
                  labelPosition="right"
                  content={t('landing.common.button.goToWiseflow')}
                  id="go-to-wiseflow-link"
                  role="link"
                />
              )}
            </WfMenu.Item>
          )}
        </WfMenu>
      </Responsive>

      {/* Small device */}
      <Responsive maxWidth={759}>
        <WfMenu secondary className="centered">
          <WfMenu.Item
            as={Link}
            to="/"
            id="frontpage-link"
            role="link"
            aria-label="WISEflow logo"
            content={<Image src={logo} alt="WISEflow logo" />}
          />
        </WfMenu>
      </Responsive>
    </header>
  );
};

export default Header;
