import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { ENV } from '../constants/env';

const mockInstance = axios.create();
const realInstance = axios.create();

export const mock = new AxiosMockAdapter(mockInstance, {
  delayResponse: 200,
});

export default ENV.MOCKED ? mockInstance : realInstance;
