import { AxiosError } from 'axios';
import { ENV } from '../constants/env';
import * as JWT from '@uniwise/jwt';
import Cookies from 'js-cookie';
import { setJWTCookie } from '@uniwise/jwt/dist/cookie';
import axios from './axios';
import './login.mock';

const BASEURL = ENV.WISEFLOW_HOST;

export class ErrUnknownLoginError extends Error {
  public traceID;

  constructor(traceID?: string) {
    super();
    this.name = 'ErrUnknownLoginError';
    this.traceID = traceID;
  }
}

export class ErrLoginCredentialsMismatch extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ErrLoginCredentialsMismatch';
  }
}

export class ErrUnknownPasswordResetError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ErrUnknownPasswordResetError';
  }
}

export class ErrTooManyLoginAttempts extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ErrTooManyLoginAttempts';
  }
}

export class ErrUserDeactivated extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ErrUserDeactivated';
  }
}

export interface PostLoginResponse {
  targetURL: string;
  languageId: number;
  JWT: string;
}

export async function postLogin(
  username: string,
  password: string,
  licenseId?: string
): Promise<void> {
  const form = new FormData();
  form.append('username', username);
  form.append('password', password);
  if (licenseId) {
    form.append('licenseId', licenseId);
  }

  try {
    const response = await axios.request<PostLoginResponse>({
      method: 'post',
      url: '/controller/account/loginStdUser.php',
      baseURL: BASEURL,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    const targetURL = response.data.targetURL;
    if (!targetURL) {
      throw new ErrUnknownLoginError();
    }

    const languageId = response.data.languageId;
    if (languageId) {
      Cookies.set('languageId', `${languageId}`);
    }

    // Temporary fix for local environment
    const jwt = response.data.JWT;
    if (jwt && window.location.hostname === 'localhost') {
      setJWTCookie(jwt);
    }

    // Relative targetURL fix
    if (/^((http|https):\/\/)/.test(targetURL)) {
      const url = new URL(targetURL);
      window.location.href = targetURL.replace(url.origin, '');
    } else {
      window.location.href = targetURL;
    }
  } catch (err) {
    const response = (err as AxiosError).response;

    if (response?.status === 400) {
      throw new ErrLoginCredentialsMismatch('Incorrect username or password');
    }

    if (response?.status === 403) {
      throw new ErrUserDeactivated('User is not activated');
    }

    if (response?.status === 429) {
      throw new ErrTooManyLoginAttempts('Too many login attempts');
    }

    throw new ErrUnknownLoginError(response?.headers['x-trace-id']);
  }
}

export async function resetLogin(email: string): Promise<void> {
  const form = new FormData();
  form.append('userName', email);

  try {
    const response = await axios({
      method: 'post',
      url: '/controller/account/resetPassword.php',
      baseURL: BASEURL,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    const err = response?.data['error'];
    if (err) {
      throw new ErrUnknownPasswordResetError();
    }
  } catch {
    throw new ErrUnknownPasswordResetError();
  }
}

export function isLoggedIn(): boolean {
  return JWT.isLoggedIn();
}

export function redirectToWISEflow(): void {
  window.location.href = '/auth/redirect.php';
}
