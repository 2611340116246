import React from 'react';
import { Grid, Responsive, WfMenu } from '@uniwise/flow-ui-react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { Link } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector';

function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Responsive as={'footer'} maxWidth={759}>
      <footer>
        <Grid>
          <Grid.Row>
            <Grid.Column align="center">
              <LanguageSelector />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign="center">
            <Grid.Column>
              <WfMenu secondary className="centered">
                <WfMenu.Item
                  as="a"
                  href="https://www.uniwise.co.uk/blog-landing"
                  id="blog-button"
                  content={t('landing.common.button.blog')}
                />

                <WfMenu.Item
                  as={Link}
                  to="/changelog"
                  id="changelog-button"
                  content={t('landing.common.button.changelog')}
                />
              </WfMenu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </footer>
    </Responsive>
  );
}

export default Footer;
