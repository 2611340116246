import { WfButton, Modal, Icon, Grid } from '@uniwise/flow-ui-react';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ENV } from '../../constants/env';

const ConsentValidHours = 168; // 7 days
const envRegex = /^(stage|qa|test)$/g;

// Make this method better
const isValidEnvironment = (env: string): boolean => {
  return envRegex.test(env);
};

interface EnvironmentModalProps {
  children?: React.ReactNode | React.ReactNodeArray;
}

const EnvironmentModal: FunctionComponent<EnvironmentModalProps> = () => {
  const [visible, setVisible] = React.useState(false);
  const localStorageKey = `wf-environment-consent-${ENV.ENVIRONMENT_CONSENT}`;
  const { t } = useTranslation();

  const onConsent = () => {
    if (!ENV.ENVIRONMENT_CONSENT) {
      return;
    }

    localStorage.setItem(localStorageKey, Date.now().toString());
    setVisible(false);
  };

  useEffect(() => {
    if (!ENV.ENVIRONMENT_CONSENT) {
      return;
    }

    if (!isValidEnvironment(ENV.ENVIRONMENT_CONSENT)) {
      return;
    }

    const timestamp = parseInt(localStorage.getItem(localStorageKey) || '');
    if (Date.now() < timestamp + ConsentValidHours * 60 * 60 * 1000) {
      return;
    }

    localStorage.removeItem(localStorageKey);
    setVisible(true);
  }, []);

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={visible}
      id="environment-consent-modal"
      style={{
        maxWidth: 500,
      }}
    >
      <Modal.Header>
        <Icon name="info" />{' '}
        {t(`landing.environmentModal.${ENV.ENVIRONMENT_CONSENT}.label.title`)}
      </Modal.Header>

      <Modal.Content>
        <p
          dangerouslySetInnerHTML={{
            __html: t('landing.environmentModal.label.warning'),
          }}
        />
        <p>
          {t(
            `landing.environmentModal.${ENV.ENVIRONMENT_CONSENT}.label.description`
          )}
        </p>
      </Modal.Content>

      <Modal.Actions>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <WfButton
                onClick={() => {
                  window.location.href = 'https://wiseflow.net';
                }}
                primary
                fluid
                id="dismiss-environment-button"
                content={t(
                  'landing.environmentModal.button.goToLiveEnvironment'
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: 0 }}>
            <Grid.Column>
              <WfButton
                onClick={() => {
                  onConsent();
                }}
                fluid
                id="consent-environment-button"
                content={
                  <>
                    {t('landing.environmentModal.button.consentToEnvironment')}
                    <p style={{ opacity: 0.5, marginTop: 6 }}>
                      {t('landing.environmentModal.button.validFor')}
                    </p>
                  </>
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

export default EnvironmentModal;
