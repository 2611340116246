import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ENV } from '../constants/env';
import * as JWT from '@uniwise/jwt';

if (ENV.SENTRY_DSN && ENV.SENTRY_ENV) {
  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    environment: ENV.SENTRY_ENV,
    release: ENV.SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['europe.wiseflow.net'],
      }),
    ],
    tracesSampleRate: 0.1,
  });

  Sentry.configureScope((scope) => {
    const token = JWT.get();
    if (token) {
      scope.setUser({
        id: token.sub,
      });

      const licenseInfo = token.wiseflow?.licenseInfo;
      if (licenseInfo) {
        scope.setTag('licenseId', licenseInfo?.id);
        scope.setTag('licenseName', licenseInfo?.name);
      }
    }
  });
}
