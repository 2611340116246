import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import '@uniwise/flow-ui-react/dist/index.css';

import './utilities/favicon';
import './utilities/i18n';
import './utilities/sentry';

import Routes from './routes';

ReactDOM.render(<Routes />, document.getElementById('root'));
