import React, { useEffect, useState } from 'react';
import './LicenseLogin.scss';
import {
  Grid,
  Image,
  Divider,
  WfHeader,
  WfButton,
} from '@uniwise/flow-ui-react';
import { useParams, useHistory } from 'react-router-dom';
import ProviderList, {
  ProviderListConfig,
} from '../../components/ProviderList/ProviderList';
import { useTranslation } from 'react-i18next';
import { getInstitution } from '../../api/institution';
import { Institution } from '../../interfaces/Institution';

interface LicenseLoginParameters {
  id: string;
}

function hasNoProviders(config: ProviderListConfig): boolean {
  return Object.values(config.providers).every((b) => !b);
}

function LicenseLoginView(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams<LicenseLoginParameters>();

  const [institution, setInstitution] = useState<Institution>();
  const [providerListConfig, setProviderListConfig] =
    useState<ProviderListConfig>();

  useEffect(() => {
    (async () => {
      try {
        const inst = await getInstitution(id);

        setProviderListConfig({
          license: id,
          providers: {
            wiseflow: !!inst.standard,
            edugain: !!inst.eduGain,
            feide: !!inst.feide,
            minid: !!inst.idporten,
            wayf: !!inst.wayf,
            nemid: !!inst.nemlogin,
            unilogin: !!inst.unilogin,
            azure: !!inst.azure,
            eduGainIdp: inst.eduGainIDP,
            wayfIdp: inst.wayfIDP,
          },
        });

        setInstitution(inst);
      } catch (e) {
        console.warn(`Could not find institution with id ${id}`);
        history.push('/login/error/license');
      }
    })();
  }, [id, history]);

  return (
    <section className="LicenseLogin">
      <Grid centered padded>
        {institution?.id && (
          <Image
            src={
              'https://europe.wiseflow.net/pics/logos/dark/' +
              institution?.id +
              '.png'
            }
            alt={institution?.name}
            size="tiny"
          />
        )}

        {institution?.name && (
          <Grid.Row>
            <Grid.Column>
              <WfHeader as="h2" textAlign="center">
                {institution?.name}

                {!institution?.image && (
                  <WfHeader.Subheader>
                    {t(
                      'landing.login.institution.label.licenseLoginExplanation'
                    )}
                  </WfHeader.Subheader>
                )}
              </WfHeader>
            </Grid.Column>
          </Grid.Row>
        )}

        {institution?.image && (
          <>
            <Grid.Row>
              <Grid.Column>
                <Image
                  src={institution?.image}
                  id="institution-image"
                  alt={institution?.name}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <WfHeader as="h2" textAlign="center">
                  <WfHeader.Subheader>
                    {t(
                      'landing.login.institution.label.licenseLoginExplanation'
                    )}
                  </WfHeader.Subheader>
                </WfHeader>
              </Grid.Column>
            </Grid.Row>
          </>
        )}

        {providerListConfig && (
          <Grid.Row>
            <Grid.Column>
              {ProviderList(providerListConfig)}

              {hasNoProviders(providerListConfig) && (
                <div className="NoProviders">
                  {t('landing.login.institution.label.noProvidersForLicense')}
                </div>
              )}

              <Divider />

              <WfButton
                fluid
                onClick={history.goBack}
                size="large"
                secondary
                id="back-link"
                role="link"
                content={t('landing.common.button.back')}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </section>
  );
}

export default LicenseLoginView;
