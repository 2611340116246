import { ENV } from '../constants/env';
import { Institution } from '../interfaces/Institution';
import axios from './axios';
import './institution.mock';

const BASEURL = ENV.WISEFLOW_HOST;

const CACHE_TTL_MS = 60000; // 1 min

let cache: Institution[];
let cacheTimestamp: number;

export class ErrFailedToRetrieveInstitutions extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ErrFailedToRetrieveInstitutions';
  }
}

export class ErrFailedToRetrieveInstitution extends Error {
  constructor(id: string | number) {
    super(`Could not find institution with id: ${id}`);
    this.name = 'ErrFailedToRetrieveInstitution';
  }
}

export class ErrFailedToGetLicenseIDByAlias extends Error {
  constructor(country: string, alias: string) {
    super(`Could not get license id for country ${country} and alias ${alias}`);
    this.name = 'ErrFailedToGetLicenseIDByAlias';
  }
}

function isCacheValid(): boolean {
  if (!cache || !cacheTimestamp) {
    return false;
  }

  return Date.now() - cacheTimestamp < CACHE_TTL_MS;
}

export async function getInstitutions(): Promise<Institution[]> {
  if (!isCacheValid()) {
    try {
      const response = await axios.get<Institution[]>(`/api-external/login`, {
        baseURL: BASEURL,
      });

      cache = response.data.sort((a: Institution, b: Institution) =>
        a.name.localeCompare(b.name)
      );

      cacheTimestamp = Date.now();
    } catch {
      throw new ErrFailedToRetrieveInstitutions();
    }
  }

  return cache;
}

export async function getInstitution(
  id: number | string
): Promise<Institution> {
  const institutions = await getInstitutions();
  const numberId = parseInt(id as string);

  for (const i of institutions) {
    if (i.id === numberId) {
      return i;
    }
  }

  throw new ErrFailedToRetrieveInstitution(id);
}

export interface GetInstitutionByAliasResponse {
  licenseId: number;
}

export async function getInstitutionIDByAlias(
  country: string,
  alias: string
): Promise<number> {
  try {
    const response = await axios.get<GetInstitutionByAliasResponse>(
      `/controller/light/license-id.php`,
      {
        baseURL: BASEURL,
        params: {
          country: country,
          alias: alias,
        },
      }
    );

    if (response.status !== 200) {
      throw new ErrFailedToGetLicenseIDByAlias(country, alias);
    }

    return response.data.licenseId;
  } catch {
    throw new ErrFailedToGetLicenseIDByAlias(country, alias);
  }
}
