import React from 'react';
import './SelectLogin.scss';
import { observer } from 'mobx-react';
import ProviderList, {
  ProviderListConfig,
} from '../../components/ProviderList/ProviderList';
import { Grid, Divider, WfButton, WfHeader } from '@uniwise/flow-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SelectLoginView = (): JSX.Element => {
  const { t } = useTranslation();

  const providersConfig: ProviderListConfig = {
    providers: {
      wayf: true,
      feide: true,
      edugain: true,
      wiseflow: true,
    },
  };

  return (
    <section className="SelectLogin">
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <WfHeader as="h2" textAlign="center">
              {t('landing.login.overview.label.selectLoginMethod')}
              <WfHeader.Subheader>
                {t('landing.login.overview.label.selectLoginMethodExplanation')}
              </WfHeader.Subheader>
            </WfHeader>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {ProviderList(providersConfig)}

            <Divider horizontal>{t('landing.common.label.or')}</Divider>

            <WfButton
              as={Link}
              role="link"
              to="/login/search"
              primary
              size="large"
              fluid
              icon={{
                icon: 'search',
                label: t('landing.login.overview.button.searchForInstitution'),
              }}
              content={t('landing.login.overview.button.searchForInstitution')}
              id="login-search-link"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  );
};

export default observer(SelectLoginView);
