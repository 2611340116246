import { mock } from './axios';

mock.onGet('/api-external/login').reply(() => {
  return [
    200,
    [
      {
        id: 1,
        name: 'UNIwise',
        organisation: 'uniwise.dk',
        standard: '13',
        wayf: null,
        eduGain: null,
        nemlogin: '16',
        unilogin: '17',
        idporten: null,
        feide: null,
        azure: '28',
        wayfIDP: null,
        eduGainIDP: null,
        image: '',
      },
      {
        id: 2,
        name: 'Aarhus BSS',
        organisation: 'au.dk',
        standard: null,
        wayf: '14',
        eduGain: null,
        nemlogin: '16',
        unilogin: null,
        idporten: null,
        feide: null,
        azure: null,
        wayfIDP: 'https://wayf.au.dk',
        eduGainIDP: null,
        image: '',
      },
    ],
  ];
});

mock.onGet('/controller/light/license-id.php').reply(() => {
  return [200, { licenseId: 1 }];
});
