import React, { useState, useEffect } from 'react';
import './SearchForm.scss';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Divider,
  List,
  Input,
  Loader,
  WfHeader,
  Message,
  variables,
} from '@uniwise/flow-ui-react';
import ListItem from './ListItem';
import { Institution } from '../../../interfaces/Institution';
import { observer } from 'mobx-react';
import { getInstitutions } from '../../../api/institution';

const VISIBLE_ITEMS = 4;

function SearchForm(): JSX.Element {
  const { t } = useTranslation();

  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [results, setResults] = useState<Institution[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>();

  function handleSearchChange(query: string): void {
    setResults(
      institutions.filter(
        (v) => v.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
    );
  }

  async function fetchInstitutions() {
    try {
      const res = await getInstitutions();
      setInstitutions(res);
      setResults(res);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchInstitutions();
    setTimeout(() => {
      document.getElementById('login-search-input')?.focus();
    }, 1);
  }, []);

  return (
    <Form className="SearchForm" id="login-search-form" role="search">
      <Form.Field>
        <Input
          accessibleLabelText={t('landing.login.search.label.institutionName')}
          accessibleLabelDirection={'block'}
          onChange={(_, data) => handleSearchChange(data.value as string)}
          size="large"
          id="login-search-input"
          role="input"
        />
      </Form.Field>

      <Divider />

      {isLoading && (
        <Form.Field align="center">
          <Loader active inline id="search-loader" />
        </Form.Field>
      )}

      {!isLoading && !error && (
        <Form.Field>
          <List id="login-result-list">
            {results
              .slice(0, VISIBLE_ITEMS)
              .map((item: Institution) => ListItem(item))}
            {results && results?.length === 0 && (
              <WfHeader
                as="h3"
                textAlign="center"
                style={{ color: variables.colors.text.muted }}
              >
                {t('landing.login.search.label.noResults')}
              </WfHeader>
            )}
          </List>
        </Form.Field>
      )}

      <Message
        error
        visible={!!error}
        header={t('landing.common.label.error')}
        content={t(
          'landing.login.institution.label.loadingInstitutionsFailedExplanation'
        )}
        id="unknown-error-message"
        role="alert"
      />
    </Form>
  );
}

export default observer(SearchForm);
