import React, { useState } from 'react';
import './LoginForm.scss';
import { useTranslation } from 'react-i18next';
import { Form, WfButton, Message, Divider } from '@uniwise/flow-ui-react';
import {
  ErrLoginCredentialsMismatch,
  ErrTooManyLoginAttempts,
  ErrUnknownLoginError,
  ErrUserDeactivated,
  postLogin,
} from '../../../api/login';
import { Link } from 'react-router-dom';
import { validate } from 'email-validator';

interface IProps {
  licenseId: string;
}

function LoginForm(props: IProps): JSX.Element {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await postLogin(email, password, props?.licenseId);
    } catch (err) {
      if (err instanceof ErrLoginCredentialsMismatch) {
        setPassword('');
      }

      setLoading(false);
      setError(err);
    }
  };

  return (
    <Form
      className="LoginForm"
      onSubmit={handleSubmit}
      loading={loading}
      id="login-form"
    >
      <Form.Field required>
        <label>{t('landing.common.label.email')}</label>
        <Form.Input
          placeholder={t('landing.common.label.email')}
          name="email"
          value={email}
          onChange={(_, data) => setEmail(data.value)}
          autoFocus
          id="email-input"
          className="FormInput"
          role="input"
        />
      </Form.Field>

      <Form.Field required>
        <label>{t('landing.common.label.password')}</label>
        <Form.Input
          type="password"
          name="password"
          placeholder={t('landing.common.label.password')}
          onChange={(_, data) => setPassword(data.value)}
          id="password-input"
          className="FormInput"
          role="input"
        />
      </Form.Field>

      <Message
        error
        visible={error instanceof ErrLoginCredentialsMismatch}
        header={t('landing.login.wiseflow.label.loginFailed')}
        content={t('landing.login.wiseflow.label.loginFailedExplanation')}
        id="incorrect-email-or-password-message"
        role="alert"
      />

      <Message
        error
        visible={error instanceof ErrTooManyLoginAttempts}
        header={t('landing.common.label.error')}
        content={t('landing.login.wiseflow.label.tooManyLoginAttempts')}
        id="unknown-login-error-message"
        role="alert"
      />

      <Message
        error
        visible={error instanceof ErrUserDeactivated}
        header={t('landing.login.wiseflow.label.loginFailed')}
        content={t('landing.login.wiseflow.label.userDeactivatedExplanation')}
        id="user-deactivated-message"
        role="alert"
      />

      <Message
        error
        visible={error instanceof ErrUnknownLoginError}
        id="unknown-login-error-message"
        role="alert"
      >
        <Message.Header>{t('landing.common.label.error')}</Message.Header>
        <Message.Content>
          {t('landing.login.wiseflow.label.loginErrorExplanation')}

          {error && (error as ErrUnknownLoginError).traceID && (
            <>
              <Divider />
              <div style={{ opacity: '0.75' }}>
                {`Trace ID: ${(error as ErrUnknownLoginError).traceID}`}
              </div>
            </>
          )}
        </Message.Content>
      </Message>

      <Form.Field>
        <WfButton
          type="submit"
          size="large"
          primary
          fluid
          disabled={!validate(email) || !password}
          id="login-submit-button"
          role="button"
          content={t('landing.common.button.logIn')}
        />
      </Form.Field>

      <Form.Field className="ForgotPasswordField">
        <Link
          to="/login/wiseflow/recover"
          id="forgot-password-link"
          className="WfLink"
          role="link"
        >
          {t('landing.login.wiseflow.button.forgotPassword')}
        </Link>
      </Form.Field>
    </Form>
  );
}
export default LoginForm;
