import React from 'react';
import './WiseflowLogin.scss';
import LoginForm from './Form/LoginForm';
import { Grid, Divider, WfButton } from '@uniwise/flow-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface WiseflowLoginParameters {
  id: string;
}

function WiseflowLoginView(): JSX.Element {
  const { id } = useParams<WiseflowLoginParameters>();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <section className="WiseflowLogin">
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <LoginForm licenseId={id} />

            <Divider />

            <WfButton
              fluid
              onClick={history.goBack}
              size="large"
              secondary
              id="back-link"
              role="link"
              content={t('landing.common.button.back')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  );
}

export default WiseflowLoginView;
