import React from 'react';
import './Frontpage.scss';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  WfHeader,
  WfButton,
  Transition,
  Responsive,
} from '@uniwise/flow-ui-react';
import { Link } from 'react-router-dom';
import mr from './mr.svg';
import waves from './waves_white.svg';
import { isLoggedIn, redirectToWISEflow } from '../../api/login';

function FrontpageView(): JSX.Element {
  const { t } = useTranslation();

  const loggedIn = isLoggedIn();

  const title = 'More than paperless';
  const subtitle =
    'A digital exam and assessment platform that is easy to use and provides great functionality.';

  return (
    <>
      <section className="Frontpage">
        {/* big devices */}
        <Responsive as={Grid} minWidth={760} className="Desktop">
          <Grid.Row columns={2}>
            <Grid.Column className="Figure" textAlign="center">
              <Transition
                transitionOnMount
                animation="fade right"
                visible={true}
              >
                <div>
                  <img src={mr} alt="" aria-hidden />
                </div>
              </Transition>
            </Grid.Column>
            <Grid.Column>
              <Transition transitionOnMount animation="fade left">
                <Grid className="Text">
                  <Responsive as={Grid} minWidth={1250}>
                    <Grid.Row>
                      <WfHeader className="Title">{title}</WfHeader>
                      <div className="Subtitle">{subtitle}</div>
                    </Grid.Row>
                  </Responsive>
                  <Responsive as={Grid} maxWidth={1249}>
                    <Grid.Row>
                      <WfHeader style={{ fontSize: 38, fontWeight: 500 }}>
                        {title}
                      </WfHeader>
                    </Grid.Row>
                    <Grid.Row>
                      <WfHeader
                        style={{
                          fontSize: 20,
                          fontWeight: 100,
                          maxWidth: 390,
                        }}
                      >
                        {subtitle}
                      </WfHeader>
                    </Grid.Row>
                  </Responsive>
                  <Grid.Row>
                    <WfButton
                      color="green"
                      basic
                      icon={{
                        icon: 'info',
                        label: t('landing.home.button.learnMoreAboutWiseflow'),
                      }}
                      content={t('landing.home.button.learnMoreAboutWiseflow')}
                      as="a"
                      href="https://uniwise.co.uk/wiseflow"
                      id="learn-more-link"
                      role="link"
                    />
                  </Grid.Row>
                </Grid>
              </Transition>
            </Grid.Column>
          </Grid.Row>

          <Transition transitionOnMount animation="fade">
            <Grid.Row className="Waves">
              <img src={waves} alt="" aria-hidden />
            </Grid.Row>
          </Transition>
        </Responsive>

        {/* small devices */}
        <Responsive as={Grid} maxWidth={759}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Transition transitionOnMount animation="fade down">
                  <div>
                    <img src={mr} width="100" alt="WISEflow mascot" />
                  </div>
                </Transition>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Transition transitionOnMount animation="fade left">
                  <WfHeader as="h1">{title}</WfHeader>
                </Transition>
                <Transition transitionOnMount animation="fade right">
                  <WfHeader as="h4">{subtitle}</WfHeader>
                </Transition>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <WfButton
                  size="large"
                  color="green"
                  basic
                  fluid
                  as="a"
                  icon={{
                    icon: 'info',
                    label: t('landing.home.button.learnMoreAboutWiseflow'),
                  }}
                  labelPosition="right"
                  href="https://uniwise.co.uk/wiseflow"
                  content={t('landing.home.button.learnMoreAboutWiseflow')}
                  className="DisableIconBackground"
                  id="learn-more-link"
                  role="link"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {!loggedIn && (
                  <WfButton
                    as={Link}
                    to="/login"
                    size="large"
                    fluid
                    color="green"
                    icon={{
                      icon: 'unlock',
                      label: t('landing.common.button.logIn'),
                    }}
                    labelPosition="right"
                    content={t('landing.common.button.logIn')}
                    id="login-link"
                    role="link"
                  />
                )}
                {loggedIn && (
                  <WfButton
                    as="a"
                    onClick={redirectToWISEflow}
                    color="green"
                    size="large"
                    fluid
                    icon={{
                      icon: 'arrow right',
                      label: t('landing.common.button.goToWiseflow'),
                    }}
                    labelPosition="right"
                    content={t('landing.common.button.goToWiseflow')}
                    id="go-to-wiseflow-link"
                    role="link"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      </section>
    </>
  );
}

export default FrontpageView;
