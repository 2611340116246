import React from 'react';
import './ProviderList.scss';
import { Form, WfButton, Image } from '@uniwise/flow-ui-react';
import { Link } from 'react-router-dom';
import { ENV } from '../../constants/env';

import wayfLogo from './WAYF.svg';
import eduGAINLogo from './edugain.png';
import feideLogo from './feide.svg';
import wiseflowLogo from './wiseflow.svg';
import microsoftLogo from './microsoft.png';
import mitIdLogo from './MitID.svg';
import uniloginLogo from './unilogin.svg';
import minidLogo from './minid.png';
import { toBase64 } from 'js-base64';

const BASEURL = ENV.WISEFLOW_HOST;

export interface ProviderListConfig {
  license?: string;
  providers: {
    wiseflow?: boolean;
    wayf?: boolean;
    edugain?: boolean;
    nemid?: boolean;
    feide?: boolean;
    minid?: boolean;
    unilogin?: boolean;
    azure?: boolean;
    eduGainIdp?: string;
    wayfIdp?: string;
  };
}

function getProviderURL(
  moduleId: number,
  licenseId?: string,
  idp?: string
): string {
  let link = `${BASEURL}/auth/login.php?login&module=${moduleId}`;

  if (licenseId) {
    link += `&licenseId=${licenseId}`;
  }

  if (idp) {
    link += `&IDPList=${toBase64(idp)}`;
  }

  return link;
}

function ProviderList(config: ProviderListConfig): JSX.Element {
  return (
    <Form className="ProviderList">
      {config.providers.wayf && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            id="wayf-login-link"
            href={getProviderURL(14, config.license, config.providers.wayfIdp)}
            role="link"
            aria-label="WAYF"
            content={<Image src={wayfLogo} alt="WAYF" centered />}
          />
        </Form.Field>
      )}

      {config.providers.nemid && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            className="mitid"
            id="mitid-login-link"
            href={getProviderURL(16, config.license)}
            role="link"
            aria-label="MitID"
            content={<Image src={mitIdLogo} alt="MitID" centered />}
          />
        </Form.Field>
      )}

      {config.providers.unilogin && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            id="unilogin-login-link"
            href={getProviderURL(17, config.license)}
            role="link"
            aria-label="Unilogin"
            content={<Image src={uniloginLogo} alt="Unilogin" centered />}
          />
        </Form.Field>
      )}

      {config.providers.minid && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            className="minid"
            id="minid-login-link"
            href={getProviderURL(18, config.license)}
            role="link"
            aria-label="MINid"
            content={<Image src={minidLogo} alt="MINid" centered />}
          />
        </Form.Field>
      )}

      {config.providers.feide && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            className="feide"
            id="feide-login-link"
            href={getProviderURL(19, config.license)}
            role="link"
            aria-label="FEIDE"
            content={<Image src={feideLogo} alt="FEIDE" centered />}
          />
        </Form.Field>
      )}

      {config.providers.edugain && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            className="edugain"
            id="edugain-login-link"
            href={getProviderURL(
              15,
              config.license,
              config.providers.eduGainIdp
            )}
            role="link"
            aria-label="eduGAIN"
            content={<Image src={eduGAINLogo} alt="eduGAIN" centered />}
          />
        </Form.Field>
      )}

      {config.providers.azure && (
        <Form.Field>
          <WfButton
            fluid
            basic
            size="large"
            className="microsoft"
            id="azure-login-link"
            href={getProviderURL(28, config.license)}
            role="link"
            aria-label="Microsoft"
            content={<Image src={microsoftLogo} alt="Microsoft" centered />}
          />
        </Form.Field>
      )}

      {config.providers.wiseflow && (
        <Form.Field>
          <WfButton
            as={Link}
            fluid
            basic
            size="large"
            id="wiseflow-login-link"
            to={
              config.license
                ? `/login/license/${config.license}/wiseflow`
                : '/login/wiseflow'
            }
            role="link"
            aria-label="WISEflow"
            content={<Image src={wiseflowLogo} alt="WISEflow" centered />}
          />
        </Form.Field>
      )}
    </Form>
  );
}

export default ProviderList;
