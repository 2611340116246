interface Environment {
  MOCKED?: boolean;

  // test, dev or stage
  FAVICON?: string;

  ENVIRONMENT_CONSENT?: 'test' | 'qa' | 'stage';

  API_HOST: string;
  WISEFLOW_HOST: string;

  I18N_ID: string;

  SENTRY_DSN: string;
  SENTRY_ENV: string;
  SENTRY_RELEASE: string;

  CHANGELOG_PAGINATION_SIZE: number;
  CHANGELOG_TAGS: string[];
}

declare global {
  interface Window {
    // eslint-disable-next-line
    SERVER_DATA: any;
  }
}

window.SERVER_DATA = window.SERVER_DATA || {};

const ENV: Environment = {
  MOCKED: process.env['REACT_APP_MOCKED'] ?? false,

  API_HOST: process.env['REACT_APP_API_HOST'] ?? '',
  WISEFLOW_HOST: process.env['REACT_APP_WISEFLOW_HOST'] ?? '',
  I18N_ID: process.env['REACT_APP_I18N_ID'],

  FAVICON: process.env['NODE_ENV'] === 'development' ? 'local' : undefined,

  SENTRY_DSN: process.env['REACT_SENTRY_DSN'],
  SENTRY_ENV: process.env['REACT_SENTRY_ENV'],
  SENTRY_RELEASE: process.env['REACT_SENTRY_RELEASE'],

  CHANGELOG_PAGINATION_SIZE: 20,
  CHANGELOG_TAGS: [],

  ...window.SERVER_DATA,
};

export { ENV };
