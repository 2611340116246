import React from 'react';
import './SearchLogin.scss';
import { Grid, Divider, WfHeader, WfButton } from '@uniwise/flow-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SearchForm from './Form/SearchForm';

function SearchLoginView(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <section className="SearchLogin">
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <WfHeader as="h1" textAlign="center">
              {t('landing.login.overview.button.searchForInstitution')}
            </WfHeader>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <SearchForm />

            <Divider />

            <WfButton
              fluid
              onClick={history.goBack}
              size="large"
              secondary
              id="back-link"
              role="link"
              content={t('landing.common.button.back')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  );
}

export default SearchLoginView;
