import { ENV } from '../constants/env';

const allowedEnvs = ['test', 'dev', 'stage', 'local'];
const currentEnv = (ENV.FAVICON || '').toLowerCase();

if (allowedEnvs.indexOf(currentEnv) > -1) {
  const link: HTMLLinkElement | null =
    document.querySelector("link[rel*='icon']");
  if (link) {
    link.href = `${process.env.PUBLIC_URL}/static/icons/${currentEnv}.ico`;
  }
}
