import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { ENV } from '../constants/env';
import { ISOLanguage, CookieName, getLanguage } from '@uniwise/i18n';

const backends = [];
const backendOptions = [];

// Remote i18n
if (ENV.API_HOST && ENV.I18N_ID) {
  backends.push(HttpBackend);
  backendOptions.push({
    loadPath: `${ENV.API_HOST}/i18n/v1/project/${ENV.I18N_ID}/language/{{lng}}`,
  });
}

const getValidBrowserLanguage = () => {
  const browserLanguage = getBrowserLanguage();

  if (Object.values(ISOLanguage).includes(browserLanguage as ISOLanguage)) {
    return browserLanguage;
  } else {
    return ISOLanguage.ENGLISH;
  }
};

// Fallback to local
backends.push(HttpBackend);
backendOptions.push({
  loadPath: `${process.env.PUBLIC_URL}/static/i18n/{{lng}}.json`,
});

// Initialize i18next
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      backends: backends,
      backendOptions: backendOptions,
    },
    detection: {
      order: ['cookie'],
      lookupCookie: CookieName,
      caches: ['cookie'],
    },
    lng: getLanguage()?.code || getValidBrowserLanguage(),
    fallbackLng: ISOLanguage.ENGLISH,
  });

function getBrowserLanguage(): string | undefined {
  const langStr = navigator.language;
  if (!langStr) {
    return;
  }

  const splitLang = langStr.split('-');
  if (splitLang.length === 0) {
    return;
  }

  return splitLang[0].toLowerCase();
}
