import React, { useEffect, useState } from 'react';
import { WfDropdown, Icon } from '@uniwise/flow-ui-react';
import i18next from 'i18next';

import { setLanguage, Languages, ISOLanguage, ISOFlag } from '@uniwise/i18n';

interface LanguageOption {
  key: ISOLanguage;
  text: string;
  value: ISOLanguage;
  flag: ISOFlag;
}

const LanguageSelector: React.FC = () => {
  const [languageValue, setLanguageValue] = useState<string>('en');

  const languageOptions: LanguageOption[] = [];
  Languages.forEach((l) => {
    languageOptions.push({
      key: l.code,
      text: l.name,
      value: l.code,
      flag: l.flag,
    });
  });

  function changeLanguage(lang: ISOLanguage): void {
    setLanguage(lang);
    setLanguageValue(lang);
    i18next.changeLanguage(lang);
  }

  useEffect(() => {
    setLanguageValue(i18next.language);
  }, []);

  return (
    <WfDropdown
      button
      basic
      as={'button'}
      icon={<Icon name="world" style={{ margin: 0, marginLeft: '0.5em' }} />}
      style={{ padding: '0.755em 1em' }}
      accessibleLabelText={languageValue}
      visibleLabel={false}
      options={languageOptions}
      value={languageValue}
      onChange={(_, data) => changeLanguage(data.value as ISOLanguage)}
      id="language-selector-listbox"
    />
  );
};

export default LanguageSelector;
